import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Imatge from '../assets/image.png';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { postTempPres, postTemp, postLux, postBp, postNoise, postRh, postSd, postTvoc, postEco2, postPm10, postPm25, postPm1 } from '../api.js';
import '../App.css';
import { Link } from 'react-router-dom';

const UploadButton = styled('input')({
  display: 'none',
});

const PrendreMostres = () => {
  const [currentLevel, setCurrentLevel] = useState(1);
  const [formData, setFormData] = useState({
    escola: '',
    data: '',
    usuari: '',
    temperatura: '',
    pressio: '',
    lux: '',
    bp: '',
    noise: '',
    rh: '',
    sd: '',
    tvoc: '',
    eco2: '',
    pm10: '',
    pm25: '',
    pm1: '',
    // Puedes añadir más campos aquí si es necesario
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextLevel = () => {
    if (currentLevel < 8) {
      setCurrentLevel(currentLevel + 1);
    } else {
      handleSave(); // Llama a la función de guardado cuando se completa el último nivel
    }
  };

  const handlePrevLevel = () => {
    if (currentLevel > 1) {
      setCurrentLevel(currentLevel - 1);
    }
  };

  const handleSave = async () => {
    try {
      if (formData.temperatura || formData.pressio) {
        await postTempPres({
          temp: formData.temperatura,
          pres: formData.pressio,
          start_time: formData.data,
          lat: 0,
          lon: 0
        });
      }

      if (formData.temperatura) {
        await postTemp({ temp: formData.temperatura });
      }

      if (formData.lux) {
        await postLux({ lux: formData.lux });
      }

      if (formData.bp) {
        await postBp({ bp: formData.bp });
      }

      if (formData.noise) {
        await postNoise({ noise: formData.noise });
      }

      if (formData.rh) {
        await postRh({ rh: formData.rh });
      }

      if (formData.sd) {
        const formDataObj = new FormData();
        formDataObj.append('depth', formData.sd);
        formDataObj.append('lat', 0);
        formDataObj.append('lon', 0);
        formDataObj.append('time', formData.data);
        await postSd(formDataObj);
      }

      if (formData.tvoc) {
        await postTvoc({ tvoc: formData.tvoc });
      }

      if (formData.eco2) {
        await postEco2({ eco2: formData.eco2 });
      }

      if (formData.pm10) {
        await postPm10({ pm10: formData.pm10 });
      }

      if (formData.pm25) {
        await postPm25({ pm25: formData.pm25 });
      }

      if (formData.pm1) {
        await postPm1({ pm1: formData.pm1 });
      }

      alert('Dades guardades correctament!');
    } catch (error) {
      console.error('Error al guardar la muestra:', error);
    }
  };

  const renderCurrentLevel = () => {
    switch (currentLevel) {
      case 1:
        return (
          <Box>
            <Typography variant="h4" align="center">Introduir les dades bàsiques</Typography>
            <TextField
              label="Escola"
              name="escola"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.escola}
              onChange={handleInputChange}
            />
            <TextField
              label="Data"
              name="data"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.data}
              onChange={handleInputChange}
            />
            <TextField
              label="Usuari"
              name="usuari"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.usuari}
              onChange={handleInputChange}
            />
            
          </Box>
        );
        case 2:
            return (
              <Box>
                <Typography variant="h3" align="center">PRIMER NIVELL</Typography>
                <Typography variant="h4" align="center">INFORMACIÓ I GEOLOCALITZACIÓ</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1">
                      De cara a tenir localitzades les mostres obtingudes, és important que, abans de començar, sapiguem on esteu. És per això que el primer pas és fer una foto de l’espai on treballareu.
                    </Typography>
                    <Typography variant="body1">
                      És important que tingueu activat el geolocalitzador de la càmera:
                    </Typography>
                    <ul>
                      <li>Activeu la geolocalització a la tauleta</li>
                      <li>Feu una foto a l’espai on treballareu. La foto va millor que sigui de la vostra parcel·la de treball, no general del parc o bosc.</li>
                      <li>Cliqueu el botó on posa afegir foto</li>
                      <li>Envieu la fotografia</li>
                      <li>Cliqueu al botó següent</li>
                    </ul>
                    <label htmlFor="upload-photo">
                      <UploadButton
                        accept="image/*"
                        id="upload-photo"
                        type="file"
                        onChange={(e) => console.log(e.target.files[0])} // Maneja la subida de fotos
                      />
                      <br></br>
                      <Button variant="contained" component="span">
                        AFEGIR FOTO
                      </Button>
                    </label>
                  </Box>
                  <Box sx={{ flex: 1, textAlign: 'center', marginTop: '10px' }}>
                    <img
                      src={Imatge}  // Substitueix aquest camí pel camí correcte a la teva imatge
                      alt="Exemple de zona de treball"
                      style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
                    />
                  </Box>
                </Box>
              </Box>
            );
      case 3:
        return (
          <Box>
            <Typography variant="h3" align="center">SEGON NIVELL</Typography>
            <Typography variant="h4" align="center">Introduir la temperatura</Typography>
            <TextField
              label="Temperatura"
              name="temperatura"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.temperatura}
              onChange={handleInputChange}
            />
          </Box>
        );
      case 4:
        return (
          <Box>
            <Typography variant="h3" align="center">TERCER NIVELL</Typography>
            <Typography variant="h4" align="center">Introduir la pressió atmosfèrica</Typography>
            <TextField
              label="Pressió"
              name="pressio"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.pressio}
              onChange={handleInputChange}
            />
          </Box>
        );
      case 5:
        return (
          <Box>
            <Typography variant="h3" align="center">QUART NIVELL</Typography>
            <Typography variant="h4" align="center">Introduir la intensitat de llum</Typography>
            <TextField
              label="Lux"
              name="lux"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.lux}
              onChange={handleInputChange}
            />
          </Box>
        );
      case 6:
        return (
          <Box>
            <Typography variant="h4" align="center">Nivell 5: Introduir el nivell de soroll</Typography>
            <TextField
              label="Soroll"
              name="noise"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.noise}
              onChange={handleInputChange}
            />
          </Box>
        );
      case 7:
        return (
          <Box>
            <Typography variant="h4" align="center">Nivell 6: Introduir la humitat relativa</Typography>
            <TextField
              label="Humitat"
              name="rh"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.rh}
              onChange={handleInputChange}
            />
          </Box>
        );
      case 8:
        return (
          <Box>
            <Typography variant="h4" align="center">Felicitats! Has completat tots els nivells!</Typography>
            <Button variant="contained" onClick={handleSave}>Guardar dades</Button>
          </Box>
        );
      default:
        return (
          <Box>
            <Typography variant="h4" align="center">Nivell desconegut!</Typography>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ padding: '1rem' }}>
      {renderCurrentLevel()}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
        <Button
          variant="contained"
          className="buttonStyle2"
          onClick={handlePrevLevel}
          disabled={currentLevel === 1}
        >
          {"< Enrere"}
        </Button>
        <Button
          variant="contained"
          className="buttonStyle2"
          onClick={handleNextLevel}
        >
          {currentLevel === 7 ? "Finalitzar" : "Següent >"}
        </Button>
        
      </Box>
      <Box sx={{ display: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
        <Button
              variant="contained"
              className="buttonStyle3"
              component={Link}
              to="/inici"
              sx={{ padding: '1rem 2rem', fontSize: '1.5rem' }} // Tamaño más grande
        >
              INICI
            </Button>
            </Box>
    </Box>
  );
};

export default PrendreMostres;
