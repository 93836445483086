import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImatgeMaterials from '../assets/Materials.png'; // Assegura't que la ruta sigui correcta
import '../App.css'; // Importa el CSS

const MaterialsPage = () => {
  return (
    <Box className="materials-container">
      <Typography variant="h4" component="h1" gutterBottom align="center" color= 'var(--gray-color)'>
        Materials
      </Typography>
      <Typography variant="body1" align="center">
        Aquí pots trobar informació detallada sobre el material.
      </Typography>

      <Box className="materials-box">
        <img
          src={ImatgeMaterials}
          alt="Materials"
          className="materials-img"
        />
      </Box>
    </Box>
  );
};

export default MaterialsPage;
