import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, Select, TextField } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc7Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    waterBool: '',
    waterSource: [],
    waterFlow: '',
    waterMovement: [],
    waterColor: [],
    waterTransparency: '',
    waterTransparencyLevel: [],
    waterTemperature: '',
    waterTemperatureCategory: [],
    waterPH: '',
    waterPHCategory: [],
    aquaticAnimalBool: '',
    aquaticAnimalCategory: [],
    aquaticPlantBool: '',
    aquaticPlantCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_7/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 8) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test',
        'Content-Type': 'multipart/form-data',
      };

      const formDataToSend = new FormData();
      formDataToSend.append('document', JSON.stringify(formData));

      if (photo) {
        formDataToSend.append('file', photo, photo.name);
      }

      const response = await axios.post('https://soil.quanta-labs.com/form/pregunta_7/', formDataToSend, { headers });

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());
      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };

  const renderSelectWithCheckbox = (field, options, label) => (
    <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={formData[field]}
        onChange={(e) => handleChange(field)(e)}
        renderValue={(selected) => selected.join(', ')}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={formData[field].indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              AIGUA!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.1: Hi podeu observar aigua?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterBool}
                onChange={handleChange('waterBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.2: D’on prové l’aigua que heu observat al parc?
            </Typography>
            {renderSelectWithCheckbox('waterSource', [
              'Aigua de pluja',
              'Aigua subterrània',
              'Aigua d’un riu o rierol',
              'Aigua de fonts artificials',
              'Aigua acumulada en estanys o llacs artificials',
              'Aigua de reg o sistemes de drenatge',
            ], "Font d'Aigua")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.3: L’aigua del parc que esteu analitzant circula?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterFlow}
                onChange={handleChange('waterFlow')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.3: Quin moviment fa l’aigua?
            </Typography>
            {renderSelectWithCheckbox('waterMovement', [
              'Infiltració',
              'Percolació',
              'Capil·laritat',
            ], "Moviment de l'Aigua")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.4: L’aigua del parc que esteu analitzant quin color té?
            </Typography>
            {renderSelectWithCheckbox('waterColor', [
              'Aigua clara',
              'Aigua marronosa o groguenca',
              'Aigua verda',
              'Aigua negra o molt fosca',
              'Aigua amb tons rogencs o ataronjats',
            ], "Color de l'Aigua")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 4:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.5: L’aigua que observeu al parc, és transparenta?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterTransparency}
                onChange={handleChange('waterTransparency')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.5: Quin grau de transparència té?
            </Typography>
            {renderSelectWithCheckbox('waterTransparencyLevel', [
              'Aigua transparent',
              'Aigua lleugerament tèrbola',
              'Aigua molt tèrbola',
            ], "Grau de Transparència")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 5:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.6: Quina temperatura té l’aigua del parc que esteu observant?
            </Typography>
            <TextField
              label="Temperatura de l'Aigua (°C)"
              variant="outlined"
              fullWidth
              value={formData.waterTemperature}
              onChange={handleChange('waterTemperature')}
              type="number"
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.6: A quina categoria la podem ubicar?
            </Typography>
            {renderSelectWithCheckbox('waterTemperatureCategory', [
              'Aigua freda (per sota de 15 °C)',
              'Aigua temperada (entre 15 °C i 25 °C)',
              'Aigua càlida (per sobre de 25 °C)',
            ], "Categoria de Temperatura")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 6:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.7: Quin grau de pH té l’aigua del parc que esteu observant?
            </Typography>
            <TextField
              label="Grau de pH de l'Aigua"
              variant="outlined"
              fullWidth
              value={formData.waterPH}
              onChange={handleChange('waterPH')}
              type="number"
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.7: A quina categoria la podem ubicar?
            </Typography>
            {renderSelectWithCheckbox('waterPHCategory', [
              'pH per sota de 7',
              'pH igual a 7',
              'pH per sobre de 7',
            ], "Categoria de pH")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 7:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.8: A l’aigua del parc que esteu analitzant, podeu observar algun animal petit aquàtic?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.aquaticAnimalBool}
                onChange={handleChange('aquaticAnimalBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.8: A quina categoria el podem ubicar?
            </Typography>
            {renderSelectWithCheckbox('aquaticAnimalCategory', [
              'Insectes aquàtics',
              'Crustacis petits',
              'Mol·luscs petits',
              'Altres invertebrats',
            ], "Categoria d'Animal Aquàtic")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 8:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.9: A l’aigua del parc que esteu analitzant, podeu observar alguna planta aquàtica?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.aquaticPlantBool}
                onChange={handleChange('aquaticPlantBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 7.9: A quina categoria la podem ubicar?
            </Typography>
            {renderSelectWithCheckbox('aquaticPlantCategory', [
              'Plantes submergides',
              'Plantes emergents',
              'Plantes flotants',
            ], "Categoria de Planta Aquàtica")}
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 8 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
    </Box>
  );
};

export default Bloc7Page;
