import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc0Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    temperatureSoil: '',
    temperatureAmbient: '',
    daysSinceRain: '',
    comments: '',
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_0/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 5) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test', // Reemplaza con tu token real si es necesario
        'Content-Type': 'application/json',
      };

      if (isEdit && documentId) {
        // Si el formulario está en modo edición, usa PATCH
        console.log(formData); // Verificar los datos que estás enviando
        const response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_0/${documentId}`, // Reemplaza con el collection_name correcto
          JSON.stringify(formData), // Enviar los datos como JSON
          { headers }
        );
        console.log('Respuesta de la API (PATCH):', response.data);
      } else {
        // Si el formulario no está en edición, usa POST
        const response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_0/',
          JSON.stringify(formData),
          { headers }
        );
        console.log('Respuesta de la API (POST):', response.data);
      }

      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());
      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };




  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              COMENCEM!!
            </Typography>
            <Typography variant="body1" gutterBottom style={{ fontSize: '1.2rem', marginTop: '1rem' }}>
              Iniciarem la recollida de dades ubicant-nos per saber on som...
            </Typography>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              FOTO PARCEL·LA!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              INSTRUCCIONS:
              <ul>
                <li>Important!! Assegura’t de tenir el geolocalitzador de la tauleta activat.</li>
              </ul>
              PREGUNTA 0.1: Fes una foto a la parcela on treballaràs l’observació
            </Typography>
            <Button variant="contained" component="label">
              Afegeix Foto
              <input type="file" hidden onChange={handlePhotoChange} />
            </Button>
            {photo && (
              <Box sx={{ marginTop: '10px' }}>
                <img
                  src={URL.createObjectURL(photo)}
                  alt="Preview"
                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                />
              </Box>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              TEMPERATURA SÒL!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ara, anem a obtenir dades de la temperatura del sòl.
              INSTRUCCIONS:
              <ul>
                <li>Agafa un element amb punxa com per exemple unes tisores.</li>
                <li>Fes un petit forat al sòl.</li>
                <li>Introdueix el termòmetre.</li>
                <li>Espera 5 minuts.</li>
                <li>Anota la temperatura del sòl.</li>
              </ul>
              PREGUNTA 0.2: Quina temperatura té el sòl?
            </Typography>
            <TextField
              label="Resposta numèrica"
              variant="outlined"
              fullWidth
              value={formData.temperatureSoil}
              onChange={handleChange('temperatureSoil')}
              type="number"
            />
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              TEMPERATURA AMBIENTAL!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ara, anem a obtenir dades de la temperatura ambiental.
              INSTRUCCIONS:
              <ul>
                <li>Agafa un termòmetre amb la mà. És important que el subjectis per la part superior.</li>
                <li>Espera 5 minuts.</li>
                <li>Anota la temperatura de l’ambient.</li>
              </ul>
              PREGUNTA 0.3: Quina és la temperatura de l’ambient?
            </Typography>
            <TextField
              label="Resposta numèrica"
              variant="outlined"
              fullWidth
              value={formData.temperatureAmbient}
              onChange={handleChange('temperatureAmbient')}
              type="number"
            />
          </>
        );
      case 4:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              DIES SENSE PLUJA!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Seguint amb les dades de l’últim dia de pluja. Si no teniu connexió ho podeu fer al centre.
              INSTRUCCIONS:
              <ul>
                <li>Agafa una tauleta o ordinador.</li>
                <li>
                  Accedeix a aquesta pàgina web:{' '}
                  <a href="https://www.meteo.cat/observacions/xema" target="_blank" rel="noopener noreferrer">
                    https://www.meteo.cat/observacions/xema
                  </a>
                </li>
                <li>Busca la teva localitat i mira quina data va ser l’última on hi va haver precipitacions.</li>
              </ul>
              PREGUNTA 0.4: Quants dies fa des de l’últim dia que va ploure al parc?
            </Typography>
            <TextField
              label="Resposta numèrica"
              variant="outlined"
              fullWidth
              value={formData.daysSinceRain}
              onChange={handleChange('daysSinceRain')}
              type="number"
            />
          </>
        );
      case 5:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              OBSERVACIONS SOBRE LA PARCEL·LA!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Finalitzarem el modul 0 o introductori amb un espai per si voleu fer alguna apreciació, comentari o
              aclariment sobre la vostra parcel·la.
              INSTRUCCIONS:
              <ul>
                <li>
                  Clica a l’apartat resposta i introdueix els teus comentaris si és necessari. En cas que no necessitis
                  dir res, clica a l’apartat següent.
                </li>
              </ul>
              PREGUNTA 0.5: Teniu algun comentari de la vostra parcel·la?
            </Typography>
            <TextField
              label="Banc de text"
              variant="outlined"
              fullWidth
              multiline
              value={formData.comments}
              onChange={handleChange('comments')}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 5 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc0Page;
