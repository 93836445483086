import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, Select } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc6Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    fungiBool: '',
    fungiType: [],
    lichenBool: '',
    lichenType: [],
    bacteriaBool: '',
    bacteriaType: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_6/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 2) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test',
        'Content-Type': 'multipart/form-data',
      };

      const formDataToSend = new FormData();
      formDataToSend.append('document', JSON.stringify(formData));

      if (photo) {
        formDataToSend.append('file', photo, photo.name);
      }

      const response = await axios.post('https://soil.quanta-labs.com/form/pregunta_6/', formDataToSend, { headers });

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');

      localStorage.setItem('updateFormList', Date.now());

      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              MICROORGANISMES!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.1: Hi podeu observar algun fong?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.fungiBool}
                onChange={handleChange('fungiBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.1: De quin tipus?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Fong</InputLabel>
              <Select
                multiple
                value={formData.fungiType}
                onChange={handleChange('fungiType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Fong"
              >
                <MenuItem value="Bolets">
                  <Checkbox checked={formData.fungiType.indexOf('Bolets') > -1} />
                  <ListItemText primary="Bolets (Xampinyó, Bolets de soca, Bolet de tinta)" />
                </MenuItem>
                <MenuItem value="Fongs de motlle">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs de motlle') > -1} />
                  <ListItemText primary="Fongs de motlle (Motlle verd, Motlle negre)" />
                </MenuItem>
                <MenuItem value="Fongs micorízics">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs micorízics') > -1} />
                  <ListItemText primary="Fongs micorízics" />
                </MenuItem>
                <MenuItem value="Fongs de putrefacció">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs de putrefacció') > -1} />
                  <ListItemText primary="Fongs de putrefacció (Saprofítics)" />
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.1: Hi podeu observar algun liquen?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.lichenBool}
                onChange={handleChange('lichenBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.1: De quin tipus?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Líquen</InputLabel>
              <Select
                multiple
                value={formData.lichenType}
                onChange={handleChange('lichenType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Líquen"
              >
                <MenuItem value="Líquen crustós">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen crustós') > -1} />
                  <ListItemText primary="Líquen crustós" />
                </MenuItem>
                <MenuItem value="Líquen foliaci">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen foliaci') > -1} />
                  <ListItemText primary="Líquen foliaci" />
                </MenuItem>
                <MenuItem value="Líquen fruticulós">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen fruticulós') > -1} />
                  <ListItemText primary="Líquen fruticulós" />
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.2: Després de fer els experiments, hi podeu observar algun bacteri?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.bacteriaBool}
                onChange={handleChange('bacteriaBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              PREGUNTA 6.2: De quin tipus?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Bacteri</InputLabel>
              <Select
                multiple
                value={formData.bacteriaType}
                onChange={handleChange('bacteriaType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Bacteri"
              >
                <MenuItem value="Bacteris nitrificants">
                  <Checkbox checked={formData.bacteriaType.indexOf('Bacteris nitrificants') > -1} />
                  <ListItemText primary="Bacteris nitrificants" />
                </MenuItem>
                <MenuItem value="Bacteris descomponedors">
                  <Checkbox checked={formData.bacteriaType.indexOf('Bacteris descomponedors') > -1} />
                  <ListItemText primary="Bacteris descomponedors" />
                </MenuItem>
                <MenuItem value="Cianobacteris">
                  <Checkbox checked={formData.bacteriaType.indexOf('Cianobacteris') > -1} />
                  <ListItemText primary="Cianobacteris" />
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 2 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc6Page;
