import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import '../App.css'; // Importa el CSS

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí pots afegir la lògica per enviar el formulari
    alert('Formulari enviat!');
  };

  return (
    <Box className="contact-form-container">
      <Typography variant="h4" component="h1" gutterBottom align="center" color= 'var(--gray-color)'>
        Contacta'ns
      </Typography>

      <form onSubmit={handleSubmit} className="contact-form">
        <TextField
          label="Nom"
          name="name_contacte"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label="Correu"
          name="email_contacte"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Missatge"
          name="message_contacte"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          margin="normal"
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          className="buttonStyle"
        >
          Enviar
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
