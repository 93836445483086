import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImatgeInstruccions from '../assets/hort.jpg'; // Assegura't que la ruta sigui correcta
import '../App.css'; // Importa el CSS

const InstruccionsPage = () => {
  return (
    <Box className="instruccions-container">
      <Typography variant="h4" component="h1" gutterBottom align="center" color= 'var(--gray-color)'>
      <strong>Instruccions per al mostreig</strong>
      </Typography>

      <Box className="instruccions-box">
        <Typography variant="body1" className="instrucions-text">
        <p>Com que els resultats varien, entre altres aspectes, segons la meteorologia i la forma de mesurar de cada persona, és molt important que intentis que les condicions durant el diagnòstic siguin sempre les mateixes (quan, on i com mesures):
        </p>
        <p><strong>Quan?</strong> A la primavera i/oa la tardor, evitant els dies de calor excessiva, fred o pluja per tractar que les condicions de temperatura i humitat siguin similars. Realitza els mesuraments allunyats en el temps de les labors del terreny.
        </p>
        <p><strong>On?</strong> Escull una zona homogènia i representativa de la parcel·la, allunyada de les vores.
        </p>
        <p><strong>Com?</strong> Al Kit de Mesura hauràs de tenir les següents eines:
        </p>
        <box className="text-gris">
            <p><span className="text-orange">• Termòmetre</span>
            </p>
            <p><span className="text-orange">• Pes (0-10 kg):</span> per pesar les collites (descriptor 1.1).
            </p>
            <p><span className="text-orange">• Pala plana:</span>per mesurar la macrofauna (2.3), els cucs (2.4, 3.5, 4.5) i per fer la fotografia. 
            </p>
            <p><span className="text-orange">• Cilindre (10 cm Ø) i martell: </span>per mesurar la capacitat d'infiltració (3.1). 
            </p>
            <p><span className="text-orange">• Vareta (8 mm Ø) i metre: </span>per mesurar la compactació (3.2). 
            </p>
            <p><span className="text-orange">• Tires de pH i got de precipitat: </span>per mesurar el pH (4.2). 
            </p>
            <p><span className="text-orange">• Smartphone i targeta de correcció de colors: </span>per estimar la humitat, la textura (3.4, 4.3, 5.2), la matèria orgànica (3.3, 4.1), el pH (4.2) i el carboni orgànic (5.1).
            </p>
            <p><span className="text-orange">• Kit per mesurar la respiració (4.4, 5.3): </span>pot gran amb tapa, pot mitjà (50 ml), pot petit (25 ml), suport de plàstic de 3 potes, xeringa per NaOH, xeringa per HCl, comptagotes per fenolftaleïna, comptagotes per a HCl 0.1 N, NaOH (0.2 N), HCl (0.1 N) i fenolftaleïna. 
            </p>
            <span className="text-orange">• Fitxa de camp</span>
        </box>
        </Typography>

        <Box>
            <img
            src={ImatgeInstruccions}
            alt="Instruccions"
            className="instruccions-img"
            />
        </Box>
      </Box>
    </Box>
  );
};

export default InstruccionsPage;