import axios from 'axios';

const API_BASE_URL = 'https://soil.quanta-labs.com';

// POST requests
export const postTempPres = (data) => {
    return axios.post(`${API_BASE_URL}/temp_pres`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postTemp = (data) => {
    return axios.post(`${API_BASE_URL}/temp`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postLux = (data) => {
    return axios.post(`${API_BASE_URL}/lux`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postBp = (data) => {
    return axios.post(`${API_BASE_URL}/bp`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postNoise = (data) => {
    return axios.post(`${API_BASE_URL}/noise`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postRh = (data) => {
    return axios.post(`${API_BASE_URL}/rh`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postSd = (formData) => {
    return axios.post(`${API_BASE_URL}/sd`, formData, {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const postTvoc = (data) => {
    return axios.post(`${API_BASE_URL}/tvoc`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postEco2 = (data) => {
    return axios.post(`${API_BASE_URL}/eco2`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postPm10 = (data) => {
    return axios.post(`${API_BASE_URL}/pm10`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postPm25 = (data) => {
    return axios.post(`${API_BASE_URL}/pm25`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

export const postPm1 = (data) => {
    return axios.post(`${API_BASE_URL}/pm1`, new URLSearchParams(data), {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
};

// DELETE requests
export const deleteRecord = (id, params) => {
    return axios.delete(`${API_BASE_URL}/record/${id}`, {
        headers: { 'accept': 'application/json' },
        params: params,
    });
};

export const deleteFile = (id) => {
    return axios.delete(`${API_BASE_URL}/file/${id}`, {
        headers: { 'accept': 'application/json' },
    });
};

// GET requests
export const getMeasurements = () => {
    return axios.get(`${API_BASE_URL}/measurements`, {
        headers: { 'accept': 'application/json' },
    });
};

export const getRecords = (id, params) => {
    return axios.get(`${API_BASE_URL}/records/${id}`, {
        headers: { 'accept': 'application/json' },
        params: params,
    });
};

export const getFiles = () => {
    return axios.get(`${API_BASE_URL}/files`, {
        headers: { 'accept': 'application/json' },
    });
};

export const getMap = (params) => {
    return axios.get(`${API_BASE_URL}/map`, {
        headers: { 'accept': 'text/html' },
        params: params,
    });
};

export const getBarChart = (params) => {
    return axios.get(`${API_BASE_URL}/bar_chart`, {
        headers: { 'accept': 'text/html' },
        params: params,
    });
};

export const getFilledLineChart = (params) => {
    return axios.get(`${API_BASE_URL}/filled_line_chart`, {
        headers: { 'accept': 'text/html' },
        params: params,
    });
};

export const getStatus = () => {
    return axios.get(`${API_BASE_URL}/status/`, {
        headers: { 'accept': 'application/json' },
    });
};
