import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png'; // Asegúrate de tener esta imagen en la carpeta correcta
import '../App.css'; // Asegúrate de tener las clases CSS correctas
import axios from 'axios';

const Bloc3Page = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID del documento desde la URL
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    rockBool: '',
    rockCategory: [],
    mineralBool: '',
    mineralCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');
  
    if (isEdit && documentId) {
      // Lógica para cargar los datos existentes
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_3/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };
  
      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 1) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit(); // Enviar datos al final del bloque
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test', // Reemplaza con tu token real si es necesario
        'Content-Type': 'multipart/form-data',
      };

      const formDataToSend = new FormData();
      formDataToSend.append(
        'document',
        JSON.stringify({
          rockBool: formData.rockBool,
          rockCategory: formData.rockCategory,
          mineralBool: formData.mineralBool,
          mineralCategory: formData.mineralCategory,
        })
      );

      if (photo) {
        formDataToSend.append('file', photo, photo.name); // Adjunta el archivo si está presente
      }

      const response = await axios.post('https://soil.quanta-labs.com/form/pregunta_3/', formDataToSend, { headers });

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');

      // Notifica a IniciPage para que actualice la tabla
      localStorage.setItem('updateFormList', Date.now());

      // Redirigir al inicio después de enviar los datos
      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              PEDRES I ROQUES!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Iniciem el tercer apartat, dedicat a pedres, roques i minerals. Concretament començarem per les pedres i roques.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situat dins de la teva parcel·la i observa-la</li>
                <li>Mira si hi ha pedres i roques. Per ajudar-te pots consultar la guia que et vam oferir a l’aula.</li>
                <li>A continuació contesta les següents preguntes sobre la presencia de pedres i roques i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 3.1: Hi podeu observar pedres o roques?
            </Typography>
            <Select
              label="Resposta"
              variant="outlined"
              fullWidth
              value={formData.rockBool}
              onChange={handleChange('rockBool')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="si">Sí</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 3.1: A quina categoria les podeu ubicar?
            </Typography>
            <Select
              label="Categoria de Roques"
              variant="outlined"
              fullWidth
              multiple
              value={formData.rockCategory}
              onChange={handleChange('rockCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="ignies">
                <Checkbox checked={formData.rockCategory.indexOf('ignies') > -1} />
                <ListItemText primary="Roques ígnies: Granit i Basalt" />
              </MenuItem>
              <MenuItem value="sedimentaries">
                <Checkbox checked={formData.rockCategory.indexOf('sedimentaries') > -1} />
                <ListItemText primary="Roques sedimentàries: Arenisca, Calcària, Conglomerat" />
              </MenuItem>
              <MenuItem value="metamorfiques">
                <Checkbox checked={formData.rockCategory.indexOf('metamorfiques') > -1} />
                <ListItemText primary="Roques metamòrfiques: Marbre, Gneis, Pissarra" />
              </MenuItem>
            </Select>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              MINERALS!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Seguim amb l’observació de minerals i la seva classificació.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situat dins de la teva parcel·la i observa-la</li>
                <li>Mira si hi ha minerals. Si a simple vista no en veus pots ajudar-te amb un petit resclat. També tens a la teva disposició la guia que et vam oferir a l’aula.</li>
                <li>A continuació contesta les següents preguntes sobre la presencia de minerals i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 3.2: Hi podeu observar minerals?
            </Typography>
            <Select
              label="Resposta"
              variant="outlined"
              fullWidth
              value={formData.mineralBool}
              onChange={handleChange('mineralBool')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="si">Sí</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 3.2: A quina categoria els podeu ubicar?
            </Typography>
            <Select
              label="Categoria de Minerals"
              variant="outlined"
              fullWidth
              multiple
              value={formData.mineralCategory}
              onChange={handleChange('mineralCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="quars">
                <Checkbox checked={formData.mineralCategory.indexOf('quars') > -1} />
                <ListItemText primary="Quars" />
              </MenuItem>
              <MenuItem value="mica">
                <Checkbox checked={formData.mineralCategory.indexOf('mica') > -1} />
                <ListItemText primary="Mica" />
              </MenuItem>
              <MenuItem value="feldspat">
                <Checkbox checked={formData.mineralCategory.indexOf('feldspat') > -1} />
                <ListItemText primary="Feldspat" />
              </MenuItem>
              <MenuItem value="calcita">
                <Checkbox checked={formData.mineralCategory.indexOf('calcita') > -1} />
                <ListItemText primary="Calcita" />
              </MenuItem>
              <MenuItem value="pirita">
                <Checkbox checked={formData.mineralCategory.indexOf('pirita') > -1} />
                <ListItemText primary="Pirita" />
              </MenuItem>
              <MenuItem value="guix">
                <Checkbox checked={formData.mineralCategory.indexOf('guix') > -1} />
                <ListItemText primary="Guix" />
              </MenuItem>
            </Select>

            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 1 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc3Page;
