import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SeccioTerra from '../assets/SeccioTerra.png'; // Assegura't que la ruta sigui correcta

const ProjectePage = () => {
    return (
        <Box className="materials-container" sx={{ padding: '16px' }}>
          <Typography variant="h4" component="h1" gutterBottom align="center" color= 'var(--gray-color)'>
            El Projecte Soil Escoles
          </Typography>
          <Typography variant="body1" align= "center">
            Aquí tens informació detallada sobre el Projecte.
          </Typography>

          <Box className="projecte-box">
            <img
              src={SeccioTerra}
              alt="Projecte"
              className="projecte-img"
            />
          </Box>
        </Box>
    );
};

export default ProjectePage;
