import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, FormControl, InputLabel, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc4Page = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID del documento desde la URL
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    animalBool: '',
    animalCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');
  
    if (isEdit && documentId) {
      // Lógica para cargar los datos existentes
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_4/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };
  
      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 1) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit(); // Enviar datos al final del bloque
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test', // Reemplaza con tu token real si es necesario
        'Content-Type': 'multipart/form-data',
      };

      const formDataToSend = new FormData();
      formDataToSend.append(
        'document',
        JSON.stringify({
          animalBool: formData.animalBool,
          animalCategory: formData.animalCategory,
        })
      );

      if (photo) {
        formDataToSend.append('file', photo, photo.name); // Adjunta el archivo si está presente
      }

      const response = await axios.post('https://soil.quanta-labs.com/form/pregunta_4/', formDataToSend, { headers });

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');

      // Notifica a IniciPage para que actualice la tabla
      localStorage.setItem('updateFormList', Date.now());

      // Redirigir al inicio después de enviar los datos
      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              ANIMALS I INSECTES!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Iniciem el bloc 4, i ens endinsem en la biologia i presència de petits animals i insectes.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situat dins de la teva parcel·la i observa-la.</li>
                <li>Agafa com a recurs una lupa.</li>
                <li>Mira si hi ha petits animals o insectes. Si a simple vista no en veus pots ajudar-te amb un petit resclat per a separar vegetació, així com amb la lupa per observar-los bé. És important no fer-los mal.</li>
                <li>També tens a la teva disposició la guia que et vam oferir a l’aula.</li>
                <li>A continuació contesta les següents preguntes sobre la presència de petits animals o insectes i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 4.1: Hi podeu observar animals petits o insectes?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.animalBool}
                onChange={handleChange('animalBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 4.1: A quina categoria els podeu ubicar?
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Categoría d'Animals o Insectes</InputLabel>
              <Select
                multiple
                value={formData.animalCategory}
                onChange={handleChange('animalCategory')}
                renderValue={(selected) => selected.join(', ')}
                label="Categoría d'Animals o Insectes"
              >
                {[
                  'Formigues',
                  'Escarabats',
                  'Cucs de terra',
                  'Centpeus',
                  'Milpeus',
                  'Llimacs i cargols',
                  'Aranyes',
                  'Tisoretes',
                  'Puces',
                  'Mosques de terra',
                  'Col·lembols',
                  'Porrons (cochinillas)',
                  'Símfits (larves)',
                  'Larves d\'insectes',
                ].map((category) => (
                  <MenuItem key={category} value={category}>
                    <Checkbox checked={formData.animalCategory.indexOf(category) > -1} />
                    <ListItemText primary={category} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 0 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc4Page;
