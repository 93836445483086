import React, { useState } from 'react';
import Box from '@mui/material/Box';

const MostreigPage = () => {
  return (
    <Box>
    </Box>
  );
};

export default MostreigPage;
