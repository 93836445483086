import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc2Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    vegSiNo: '',
    plantSiNo: '',
    plantCategory: [],
    arbreSiNo: '',
    treeCategory: [],
    restorg: '',
    organicCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  const treeCategories = {
    caducifolis: 'Arbres caducifolis (de fulla caduca): com el roure, faig, plataner',
    perennifolis: 'Arbres perennifolis (de fulla perenne): com el pi, alzina, olivera',
    conifers: 'Arbres conífers: com el pi, avet, cedre',
    fruiters: 'Arbres fruiters: com la pomera, taronger, pruner',
    ornamentals: 'Arbres ornamentals: com la magnòlia, ciruera de jardí, jacaranda',
    autoctons: 'Arbres autòctons (natius): com l`alzina, roure, lledoner',
    exotics: 'Arbres exòtics (no autòctons): com l`eucaliptus, palmera, ailant',
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');
  
    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_2/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };
  
      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 4) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test', // Reemplaza con tu token real si es necesario
        'Content-Type': 'multipart/form-data',
      };

      const formDataToSend = new FormData();
      formDataToSend.append('document', JSON.stringify(formData));

      if (photo) {
        formDataToSend.append('file', photo, photo.name);
      }

      const response = await axios.post('https://soil.quanta-labs.com/form/pregunta_2/', formDataToSend, { headers });

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');

      localStorage.setItem('updateFormList', Date.now());

      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error);
      setMessage('Error al enviar datos');
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              VEGETACIÓ!!
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Comencem el bloc 2, dedicat a la vegetació.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha vegetació. Per ajudar-te pots consultar la guia que et vam oferir a l’aula.</li>
                <li>A continuació, contesta la següent pregunta i adjunta les fotografies.</li>
              </ul>
              PREGUNTA 2.1: Hi ha vegetació?
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.vegSiNo}
                onChange={handleChange('vegSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              PLANTES I HERBES!!
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Després de veure si hi ha vegetació, ara ens centrarem en les plantes i herbes.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha plantes i herbes. Per ajudar-te pots consultar la guia que et vam oferir a l’aula.</li>
                <li>A continuació, contesta les següents preguntes sobre la presència de plantes i herbes i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 2.2: Hi observeu plantes i herbes?
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.plantSiNo}
                onChange={handleChange('plantSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.2: A quina categoria les podeu ubicar?
            </Typography>
            <Select
              label="Categoria de Plantes"
              variant="outlined"
              fullWidth
              multiple
              value={formData.plantCategory}
              onChange={handleChange('plantCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Herbes gramínies">
                <Checkbox checked={formData.plantCategory.indexOf('Herbes gramínies') > -1} />
                <ListItemText primary="Herbes gramínies" />
              </MenuItem>
              <MenuItem value="Plantes de flor petita (herbes anuals)">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes de flor petita (herbes anuals)') > -1} />
                <ListItemText primary="Plantes de flor petita (herbes anuals)" />
              </MenuItem>
              <MenuItem value="Plantes rastreres (plantes tapissants)">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes rastreres (plantes tapissants)') > -1} />
                <ListItemText primary="Plantes rastreres (plantes tapissants)" />
              </MenuItem>
              <MenuItem value="Males herbes (plantes invasores)">
                <Checkbox checked={formData.plantCategory.indexOf('Males herbes (plantes invasores)') > -1} />
                <ListItemText primary="Males herbes (plantes invasores)" />
              </MenuItem>
              <MenuItem value="Falgueres">
                <Checkbox checked={formData.plantCategory.indexOf('Falgueres') > -1} />
                <ListItemText primary="Falgueres" />
              </MenuItem>
              <MenuItem value="Musgs">
                <Checkbox checked={formData.plantCategory.indexOf('Musgs') > -1} />
                <ListItemText primary="Musgs" />
              </MenuItem>
              <MenuItem value="Plantes de flor vistosa (herbes perennes o bianuals)">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes de flor vistosa (herbes perennes o bianuals)') > -1} />
                <ListItemText primary="Plantes de flor vistosa (herbes perennes o bianuals)" />
              </MenuItem>
              <MenuItem value="Arbustos petits o juvenils">
                <Checkbox checked={formData.plantCategory.indexOf('Arbustos petits o juvenils') > -1} />
                <ListItemText primary="Arbustos petits o juvenils" />
              </MenuItem>
            </Select>
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              ARBRES!!
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ara és el torn dels arbres. Anem a observar-los i classificar-los.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha arbres. Per ajudar-te pots consultar la guia que et vam oferir a l’aula.</li>
                <li>A continuació, contesta les següents preguntes sobre la presència d’arbres i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 2.3: Hi observeu arbres?
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.arbreSiNo}
                onChange={handleChange('arbreSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.3: A quina categoria els podeu ubicar?
            </Typography>
            <Select
              label="Categoria d'Arbres"
              variant="outlined"
              fullWidth
              multiple
              value={formData.treeCategory}
              onChange={handleChange('treeCategory')}
              renderValue={(selected) => selected.map((val) => treeCategories[val]).join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              {Object.keys(treeCategories).map((key) => (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={formData.treeCategory.indexOf(key) > -1} />
                  <ListItemText primary={treeCategories[key]} />
                </MenuItem>
              ))}
            </Select>
          </>
        );

      case 3:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              RESTES ORGÀNIQUES!!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Per finalitzar l'apartat de vegetació, ara buscarem restes orgàniques.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha restes orgàniques. Per ajudar-te pots consultar la guia que et vam oferir a l’aula.</li>
                <li>A continuació, contesta les següents preguntes sobre la presència de restes orgàniques i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 2.4: Hi podeu observar restes orgàniques?
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.restorg}
                onChange={handleChange('restorg')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.4: A quina categoria les podeu ubicar?
            </Typography>
            <Select
              label="Categoria de Restes Orgàniques"
              variant="outlined"
              fullWidth
              multiple
              value={formData.organicCategory}
              onChange={handleChange('organicCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Fulles caigudes">
                <Checkbox checked={formData.organicCategory.indexOf('Fulles caigudes') > -1} />
                <ListItemText primary="Fulles caigudes" />
              </MenuItem>
              <MenuItem value="Branques i tiges petites">
                <Checkbox checked={formData.organicCategory.indexOf('Branques i tiges petites') > -1} />
                <ListItemText primary="Branques i tiges petites" />
              </MenuItem>
              <MenuItem value="Flors o fruits en descomposició">
                <Checkbox checked={formData.organicCategory.indexOf('Flors o fruits en descomposició') > -1} />
                <ListItemText primary="Flors o fruits en descomposició" />
              </MenuItem>
              <MenuItem value="Herba tallada o morta">
                <Checkbox checked={formData.organicCategory.indexOf('Herba tallada o morta') > -1} />
                <ListItemText primary="Herba tallada o morta" />
              </MenuItem>
              <MenuItem value="Restes de fruits secs o llavors">
                <Checkbox checked={formData.organicCategory.indexOf('Restes de fruits secs o llavors') > -1} />
                <ListItemText primary="Restes de fruits secs o llavors" />
              </MenuItem>
              <MenuItem value="Matèria orgànica en descomposició (humus)">
                <Checkbox checked={formData.organicCategory.indexOf('Matèria orgànica en descomposició (humus)') > -1} />
                <ListItemText primary="Matèria orgànica en descomposició (humus)" />
              </MenuItem>
              <MenuItem value="Restes d'animals petits o excrements">
                <Checkbox checked={formData.organicCategory.indexOf("Restes d'animals petits o excrements") > -1} />
                <ListItemText primary="Restes d'animals petits o excrements" />
              </MenuItem>
              <MenuItem value="Miceli de fongs">
                <Checkbox checked={formData.organicCategory.indexOf('Miceli de fongs') > -1} />
                <ListItemText primary="Miceli de fongs" />
              </MenuItem>
            </Select>
          </>
        );

      case 4:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              NIVELL ASSOLIT!!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <video width="600" controls>
                {/* Aquí se puede agregar el video si es necesario */}
              </video>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 4 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc2Page;
