import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../App.css';

const IniciPage = () => {
  const [formRows, setFormRows] = useState([]);
  const [blockProgress, setBlockProgress] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const savedProgress = JSON.parse(localStorage.getItem('blockProgress')) || {};
    setBlockProgress(savedProgress);

    const fetchFormRows = async () => {
      try {
        const collectionsResponse = await axios.get('https://soil.quanta-labs.com/form/collections');
        const collections = collectionsResponse.data;

        const formRowsPromises = collections.map(async (collection) => {
          const idsResponse = await axios.get(`https://soil.quanta-labs.com/form/${collection}/document-ids`);
          const documentIds = idsResponse.data;

          return documentIds.map((id) => ({
            id,
            collection,
            data: 'No disponible', // Puedes personalizar esto con la fecha si la obtienes
            estat: 'No disponible', // Puedes personalizar esto si tienes un estado
          }));
        });

        const resolvedFormRows = await Promise.all(formRowsPromises);
        setFormRows(resolvedFormRows.flat());
      } catch (error) {
        console.error('Error al obtener los formularios:', error);
        setFormRows([]);
      }
    };

    fetchFormRows();

    const intervalId = setInterval(fetchFormRows, 5000); // Actualiza cada 5 segundos

    return () => clearInterval(intervalId);
  }, []);

  const handleStartBlock = (blockId) => {
    navigate(`/bloque/${blockId}`);
  };

  const handleDeleteForm = async (collection, id) => {
    try {
      await axios.delete(`https://soil.quanta-labs.com/form/${collection}/${id}`);
      setFormRows((prevRows) => prevRows.filter((row) => row.id !== id));
    } catch (error) {
      console.error('Error al eliminar el formulario:', error);
    }
  };

  const bloques = [
    { id: 0, titulo: 'BLOC 0: Informació general' },
    { id: 1, titulo: 'BLOC 1: Tipus de sòl' },
    { id: 2, titulo: 'BLOC 2: Vegetació' },
    { id: 3, titulo: 'BLOC 3: Pedres, roques i minerals' },
    { id: 4, titulo: 'BLOC 4: Animals petits o insectes' },
    { id: 5, titulo: 'BLOC 5: Residus' },
    { id: 6, titulo: 'BLOC 6: Microorganismes' },
    { id: 7, titulo: 'BLOC 7: Aigua' },
  ];

  return (
    <Box sx={{ padding: '16px', backgroundColor: 'white', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <Box sx={{ padding: '6px', textAlign: 'center' }}>
        <Typography variant="h5" component="h4" gutterBottom align="center" className="subtitulo-inici">
          Segueix les indicacions per a recollir les dades dels elements ambientals.
          El procés és llarg però som científics i som rigoroses i rigorosos
          <br />
        </Typography>
      </Box>

      <Box sx={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        {bloques.map((bloque) => (
          <Box key={bloque.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
            <Typography sx={{ fontFamily: 'Arial, sans-serif' }}>{bloque.titulo}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {blockProgress[bloque.id] && (
                <CheckCircle color="success" sx={{ marginRight: '8px' }} />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStartBlock(bloque.id)}
                sx={{ borderRadius: '10px', fontFamily: 'Arial, sans-serif' }}
              >
                {blockProgress[bloque.id] ? 'Completado' : 'Començar'}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ overflow: 'auto', maxHeight: '400px' }}>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table aria-label="formularios table">
            <TableHead>
              <TableRow>
                <TableCell>Bloc</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Estat</TableCell>
                <TableCell>Accions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formRows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{`Bloc ${row.collection.split('_')[1]}`}</TableCell>
                  <TableCell>{row.data}</TableCell>
                  <TableCell>{row.estat}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      component={Link}
                      to={`/bloque/${row.collection.split('_')[1]}?edit=true&id=${row.id}`}
                      sx={{ marginRight: '8px' }}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDeleteForm(row.collection, row.id)}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ height: '32px' }} />
    </Box>
  );
};

export default IniciPage;
