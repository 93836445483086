import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import '../App.css'; // Importa el CSS

const IniciSessioForm = ({ onEnviarClick }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    
    if (!formData.name) {
      newErrors.name = 'El camp Nom és obligatori.';
    }
    
    if (!formData.email) {
      newErrors.email = 'El camp Correu electrònic és obligatori.';
    }
    
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0 && onEnviarClick) {
      onEnviarClick(); // Crida la funció passada com a prop
    }
  };
  
  return (
    <Box className="contact-form-container">
      <Typography variant="h4" component="h1" gutterBottom align="center" color='var(--gray-color)'>
        Inicia sessió
      </Typography>

      <form className="contact-form" onSubmit={handleSubmit}>
        <TextField
          label="Nom"
          name="name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
        <TextField
          label="Correu electrònic"
          name="email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.email}
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <Button
          type="submit"
          variant="contained"
          className="buttonStyle"
        >
          Enviar
        </Button>
      </form>

      {errors.form && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errors.form}
        </Alert>
      )}
    </Box>
  );
};

export default IniciSessioForm;
