import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CharacterSelectionPage from './CharacterSelectionPage';
import CompoPrendreMostres from './CompoPrendreMostres';
import '../App.css';

const AplicatiuPage = () => {
  const [showCompoPrendreMostres, setShowCompoPrendreMostres] = useState(false);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const navigate = useNavigate();

  const handleCharacterSelect = (character) => {
    setSelectedCharacter(character);
    setShowCompoPrendreMostres(true);
  };

  return (
    <Box sx={{ padding: '16px', backgroundColor: 'white', minHeight: '100vh' }}>
      {!showCompoPrendreMostres ? (
        <CharacterSelectionPage onCharacterSelect={handleCharacterSelect} />
      ) : (
        <CompoPrendreMostres selectedCharacter={selectedCharacter} />
      )}

    
    </Box>
  );
};

export default AplicatiuPage;
