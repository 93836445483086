import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import axios from 'axios';

const fieldLabels = {
  temperatureSoil: 'Temperatura del Sòl',
  temperatureAmbient: 'Temperatura Ambiental',
  daysSinceRain: 'Dies des de l\'última pluja',
  comments: 'Comentaris',
  soilType: 'Tipus de Sòl',
  phLevel: 'Nivell de pH',
  phCategory: 'Categoria de pH',
  vegetation: 'Vegetació',
  plantType: 'Tipus de Planta',
  treeType: 'Tipus d\'Arbre',
  organicRemains: 'Restes Orgàniques',
  rockType: 'Tipus de Roca',
  mineralType: 'Tipus de Mineral',
  smallAnimals: 'Animals Petits',
  animalCategory: 'Categoria d\'Animal',
  wasteObserved: 'Residus Observats',
  wasteCategory: 'Categoria de Residus',
  fungiType: 'Tipus de Fong',
  lichenType: 'Tipus de Liquen',
  bacteriaType: 'Tipus de Bacteri',
  waterBool: 'Presència d\'Aigua',
  waterSource: 'Font d\'Aigua',
  waterFlow: 'Flux d\'Aigua',
  waterMovement: 'Moviment de l\'Aigua',
  waterColor: 'Color de l\'Aigua',
  waterTransparency: 'Transparència de l\'Aigua',
  waterTransparencyLevel: 'Nivell de Transparència de l\'Aigua',
  waterTemperature: 'Temperatura de l\'Aigua',
  waterTemperatureCategory: 'Categoria de Temperatura de l\'Aigua',
  waterPH: 'pH de l\'Aigua',
  waterPHCategory: 'Categoria de pH de l\'Aigua',
  aquaticAnimalBool: 'Presència d\'Animals Aquàtics',
  aquaticAnimalCategory: 'Categoria d\'Animals Aquàtics',
  aquaticPlantBool: 'Presència de Plantes Aquàtiques',
  aquaticPlantCategory: 'Categoria de Plantes Aquàtiques',
};

const Edit = () => {
  const { collection, id } = useParams(); // Obtener el nombre de la colección y el ID desde la URL
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://soil.quanta-labs.com/form/${collection}/${id}`);
        console.log('Datos recibidos:', response.data);
        setFormData(response.data);
        setFields(Object.keys(response.data).filter(field => field !== '_id' && field !== 'user'));
      } catch (error) {
        console.error('Error al obtener los datos de la muestra:', error);
      }
    };

    fetchData();
  }, [collection, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://soil.quanta-labs.com/form/${collection}/${id}`, formData);
      alert('Cambios guardados exitosamente.');
      // Redirigir al bloque correspondiente
      navigate(`/bloque/${collection.split('_')[1]}`);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      alert('Hubo un problema al guardar los cambios.');
    }
  };

  return (
    <Box sx={{ padding: '16px', backgroundColor: 'white', minHeight: '100vh' }}>
      <Typography variant="h4" component="h1" gutterBottom align="center" className="titulo-inici">
        Editar Muestra de la Colección {collection}
      </Typography>

      <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#f9f9f9', marginTop: '16px' }}>
        <form onSubmit={handleSubmit}>
          {fields.length > 0 ? (
            fields.map((field, index) => (
              <TextField
                key={index}
                label={fieldLabels[field] || field} // Muestra una etiqueta amigable o el nombre de la variable si no se encuentra
                name={field}
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData[field] || ''}
                onChange={handleChange}
              />
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No hay campos para este bloque.
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '16px' }}>
            Guardar Cambios
          </Button>
        </form>
      </Paper>

      <Box sx={{ padding: '2rem', gap: '1rem' }}>
        <Box>
          <Box sx={{ padding: '16px', textAlign: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '22px' }}>
              <Button
                variant="contained"
                className="menu-button"
                component={Link}
                to="/inici"
                sx={{ padding: '1rem 2rem', fontSize: '1.5rem' }} // Tamaño más grande
              >
                Inici
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Edit;
