import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ImatgeNen from '../assets/boy-character.png';
import ImatgeNena from '../assets/girl-character.png';
import { useNavigate } from 'react-router-dom';

const CharacterSelectionPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const savedCharacter = localStorage.getItem('selectedCharacter');
    if (savedCharacter) {
      navigate('/inici'); // Si ya seleccionó un personaje, redirigir a la página principal
    }
  }, [navigate]);

  const handleCharacterSelect = (character) => {
    localStorage.setItem('selectedCharacter', character);
    navigate('/inici'); // Redirigir a la página principal después de seleccionar el personaje
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '2rem',
        paddingTop: '2rem',
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom className="acces-mostres-title">
        Escull el teu personatge
      </Typography>
      
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '15px',
          padding: '2rem',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          maxWidth: '500px',
          marginTop: '2rem',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '2rem' }}>
          <Button onClick={() => handleCharacterSelect('nen')} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={ImatgeNen} alt="Nen" className="character-image" />
            <Typography variant="h6"></Typography>
          </Button>
          <Button onClick={() => handleCharacterSelect('nena')} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={ImatgeNena} alt="Nena" className="character-image" />
            <Typography variant="h6"></Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CharacterSelectionPage;
