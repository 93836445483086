import React, { createContext, useReducer } from 'react';

// Crear el contexto
const MyContext = createContext();

// Reductor inicial
const initialState = {
  someValue: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SOME_VALUE':
      return {
        ...state,
        someValue: action.payload,
      };
    default:
      return state;
  }
}

// Proveïdor del context
const MyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MyContext.Provider value={{ state, dispatch }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyProvider };


